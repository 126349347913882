// Here you can add other styles

//Login
.login__container--background {
  background-image: url('../assets/images/backgrounds/login_bg.png');
  background-repeat: no-repeat;
  background-position: bottom;
}
/* Scrollbar */

:root {
  scrollbar-width: thin;
  scrollbar-color: #1a9dee #ffffff;
  scrollbar-face-color: #1a9dee;
  scrollbar-shadow-color: #ffffff;
}

body::-webkit-scrollbar {
  width: 10px;
  color: #1a9dee;
}

body::-webkit-scrollbar-track {
  background: #ffffff;
}

body::-webkit-scrollbar-thumb {
  background-color: #1a9dee;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.hide-scrollbar {
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-bg-1 {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: linear-gradient(
      30deg,
      #0492da 12%,
      transparent 12.5%,
      transparent 87%,
      #0492da 87.5%,
      #0492da
    ),
    linear-gradient(150deg, #0492da 12%, transparent 12.5%, transparent 87%, #0492da 87.5%, #0492da),
    linear-gradient(30deg, #0492da 12%, transparent 12.5%, transparent 87%, #0492da 87.5%, #0492da),
    linear-gradient(150deg, #0492da 12%, transparent 12.5%, transparent 87%, #0492da 87.5%, #0492da),
    linear-gradient(
      60deg,
      #292962 25%,
      transparent 25.5%,
      transparent 75%,
      #292962 75%,
      #292962
    ),
    linear-gradient(
      60deg,
      #292962 25%,
      transparent 25.5%,
      transparent 75%,
      #292962 75%,
      #292962
    );
  background-size: 20px 35px;
  background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
}

.custom-bg-2 {
  background-color: #ff9d00;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23000' stroke-width='66.7' stroke-opacity='0.05' %3E%3Ccircle fill='%23ff9d00' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%23fb8d17' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%23f47d24' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%23ed6e2d' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%23e35f34' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%23d85239' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%23cc453e' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%23be3941' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23b02f43' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23a02644' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%23901e44' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%23801843' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%236f1341' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%235e0f3d' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%234e0c38' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%233e0933' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%232e062c' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23210024' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
